<template>
  <b-overlay :show="showOverlay">

    <validation-observer ref="updateCoupon" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Coupon</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Code"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.code"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Coupon Status"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="data.couponStatus"
                      :class="{'border-danger rounded':errors.length > 0 }"
                      :options="[{name:'active',value:'Active'},{name:'not active',value:'DeActive'}]"
                      :reduce="name => name.value"
                      class="per-page-selector w-100 d-inline-block "
                      label="name"
                      placeholder="select"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="" md="4">
              <b-form-group
                  label="Max Use"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.maxUse"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-for="(item,idx) in currencies" class="my-1" cols="12">
              <b-row>
                <b-col cols="6" md="3">
                  <b-form-group
                      :label="`Price In ${item.name}`"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        :ref="`price${idx}`"
                        :value="getPriceValue(item) ? getPriceValue(item).priceValue : ''"
                        type="number"

                    />

                  </b-form-group>
                </b-col>
                <b-col cols="6" md="3">
                  <b-form-group
                      :label="`Minimum Order Price In ${item.name}`"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        :ref="`min-price${idx}`"
                        :value="getMinPriceValue(item) ? getMinPriceValue(item).minPriceCondetion : ''"
                        type="number"

                    />

                  </b-form-group>
                </b-col>

              </b-row>

            </b-col>
            <b-col cols="3">
              <b-button
                  class=" "
                  variant="primary"
                  @click="updateCoupon"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetAllCurrencies } from '@/libs/Api/Currencies'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'
import { GetCoupon, UpdateCoupon } from '@/libs/Api/Coupon'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'update-coupon',
  title: 'update coupon',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      currencies: null,
      data: null
    }
  },

  async created() {
    await Promise.all([
      this.getAllCurrencies(),
      this.getCoupon()
    ])
  },
  methods: {
    getPriceValue(item) {
      let idx = this?.data?.couponPrices.findIndex(e => e.currencyId === item.id)
      if (idx > -1) {
        return this.data.couponPrices[idx]
      } else {
        return null
      }

    },
    getMinPriceValue(item) {
      let idx = this?.data?.couponPrices.findIndex(e => e.currencyId === item.id)
      if (idx > -1) {
        return this.data.couponPrices[idx]
      } else {
        return null
      }

    },
    async getCoupon() {
      let _this = this
      _this.showOverlay = true
      let getCoupon = new GetCoupon(_this)
      let data = {
        id: _this.$route.params.id,
      }
      getCoupon.setParams(data)
      await getCoupon.fetch(function (content) {
        _this.data = content.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getAllCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: 1,
        count: 20,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    createPayload() {
      // this.data.couponPrices = []
      let idx = null
      this.currencies.forEach((item, idx) => {
        idx = this.data.couponPrices.findIndex(e => e.currencyId === item.id)
        this.data.couponPrices[idx].priceValue = this.$refs[`price${idx}`][0]?.$el.value
        this.data.couponPrices[idx].minPriceCondition = this.$refs[`min-price${idx}`][0]?.$el.value
        // this.data.couponPrices.push({
        //   currencyId: item.id,
        //   priceValue: this.$refs[`price${idx}`][0]?.$el.value,
        //   minPriceCondition: this.$refs[`min-price${idx}`][0]?.$el.value,
        // })
      })
      return this.data
    },
    async updateCoupon() {
      let _this = this
      Helper.validateForm(_this, 'updateCoupon', async () => {
        _this.showOverlay = true
        let updateCoupon = new UpdateCoupon(_this)
        let data = this.createPayload()
        updateCoupon.setParams(data)
        await updateCoupon.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/site-setting/coupon/manage-coupons`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
